import React from 'react';
import styled, { css } from 'styled-components';
import { Container } from '@uc-common/container';
import { ButtonTypeEnum } from '@uc-common/button';

import { Link } from '../../components/Link';
import { SiteLayout } from '../../components/Layout/SiteLayout';
import { FirstScreen, SmartCDN, Platform, Uploading } from '../../components/forMain';
import { SubTitle, CTAButton } from '../../components/forMain/Typography';
import { Section } from '../../components/forMain/Section';

export const frontmatter = {
  title: 'File infrastructure for modern web apps by Uploadcare',
  description:
    'Uploadcare is a complete file uploading, media processing & content delivery platform built for contemporary developers.',
  noindex: true,
};

const SectionPadded = styled(Section)`
  padding-top: 100px;
  padding-bottom: 100px;
`;

const StyledSubTitle = styled(SubTitle)(
  ({ theme: { tablet } }) => css`
    ${tablet()} {
      max-width: 600px;
    }
  `
);

const FinalCTA = () => (
  <SectionPadded>
    <Container>
      <StyledSubTitle>Ready to get started?</StyledSubTitle>
      <CTAButton
        forwardedAs={Link}
        to="/accounts/signup/"
        type={ButtonTypeEnum.PRIMARY}
        data-analytics="btn_signup_sem_5"
      >
        Sign up
      </CTAButton>
    </Container>
  </SectionPadded>
);

const sem = ({ location }) => {
  return (
    <SiteLayout meta={{ ...frontmatter }} pathName={location.pathname} isDark>
      <FirstScreen
        ppc
        btnSignUpProps={{
          'data-analytics': 'btn_signup_sem_0',
        }}
      />
      <Platform ppc />
      <Uploading ppc />
      <SmartCDN ppc />
      <FinalCTA />
    </SiteLayout>
  );
};

export default sem;
